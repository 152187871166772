import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "current"
}
const _hoisted_2 = {
  key: 0,
  class: "vipLevelColor"
}
const _hoisted_3 = {
  key: 1,
  class: "other-level-box"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "vipLevelColor" }
const _hoisted_6 = {
  key: 2,
  class: "other-level-box"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "vipLevelColor" }
const _hoisted_9 = { class: "vip-info" }
const _hoisted_10 = { class: "left" }
const _hoisted_11 = { class: "title" }
const _hoisted_12 = {
  key: 0,
  class: "date"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "progress-body" }
const _hoisted_15 = {
  key: 0,
  class: "progress-remark"
}
const _hoisted_16 = { class: "progress-box" }
const _hoisted_17 = { class: "progress-bg" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 2 }
const _hoisted_21 = ["src"]

import type { AppVipUpNeedVOS, VipRes } from '~/type/vip'


export default /*@__PURE__*/_defineComponent({
  __name: 'VipCard',
  props: {
    memberVipInfo: { default: () => ({}) },
    levelStatus: { default: 2 },
    size: {}
  },
  setup(__props: any) {

const { $showDialog } = useNuxtApp()
const { getS3Domain } = useConstConfig()

const props = __props
const { t, locale } = useI18n()

const cardBackgroundStyle = computed(() => {
  const url = getImage('vip_bg')
  return {
    backgroundImage: `url(${url})`
  }
})

const endProgress = () => {
  return Math.min(props.memberVipInfo?.progress ?? 0, 100)
}

const showTips = () => {
  $showDialog({
    title: t('global_tips_text'),
    message: t('vip_upgrade_hint_text'),
    confirmButtonText: t('notice_center_confirm_text'),
    showCancelButton: true
  })
}

// 等级显示处理
const viewVipLevel = () => {
  const { levelStatus, memberVipInfo } = props

  if (levelStatus === 1 || levelStatus === 3) {
    return memberVipInfo?.vipLevel ?? 1
  }

  if (levelStatus === 2 && memberVipInfo?.vipLevel) {
    return memberVipInfo.vipLevel + 1
  }

  return 1
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-item", _ctx.size]),
    style: _normalizeStyle(_unref(cardBackgroundStyle))
  }, [
    (_ctx.size === 'large')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (props.levelStatus === 2)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(t)('vip_current_level_text')), 1))
            : (props.levelStatus === 3)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                  _createElementVNode("img", {
                    src: _ctx.getImage('vip_unclose'),
                    alt: ""
                  }, null, 8, _hoisted_4),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('vip_unlocked_text')), 1)
                ]))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, [
                  _createElementVNode("img", {
                    src: _ctx.getImage(`vip_close`),
                    alt: ""
                  }, null, 8, _hoisted_7),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('vip_current_status_text')), 1)
                ]))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("span", _hoisted_11, "VIP" + _toDisplayString(props.memberVipInfo?.vipLevel ?? 0), 1),
        (props.levelStatus === 2 && props.memberVipInfo?.expirationTime)
          ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(`${_ctx.formatDateLocal({
              onlyDate: true,
              locale: _unref(locale),
              date: props.memberVipInfo?.expirationTime
            })} ${_unref(t)('vip_expire_text')}`), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    (props.memberVipInfo?.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _unref(getS3Domain)() + '/' + props.memberVipInfo?.icon,
          class: "level-img",
          alt: ""
        }, null, 8, _hoisted_13))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_14, [
      (_ctx.size === 'large')
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("span", null, _toDisplayString(_unref(t)('global_relegation_points_text')) + ": " + _toDisplayString(_ctx.formatNum({
              number: props.memberVipInfo?.limitPoints || '0.00'
            })), 1),
            _createElementVNode("span", null, _toDisplayString(_unref(t)('global_accumulated_points_text')) + ": " + _toDisplayString(props.levelStatus === 1
              ? '0.00'
              : _ctx.formatNum({
                  number: props.memberVipInfo?.totalPointsOnLevel || '0.00'
                })), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", {
            class: "progress",
            style: _normalizeStyle({ width: `${endProgress()}%` })
          }, null, 4)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.size !== 'large' ? 'need-bet-small' : '', 'need-bet'])
      }, [
        (
            props.memberVipInfo?.upNeedDeposit ||
            props.memberVipInfo?.upNeedPoints
          )
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (
              !props.memberVipInfo?.hiddenNeedDeposit &&
              props.memberVipInfo?.hiddenNeedPoints
            )
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(t)('global_upgrade_requires_amount_text', {
                grade: viewVipLevel(),
                amount: _ctx.formatNum({
                  number: props.memberVipInfo?.upNeedDeposit || 0
                })
              })), 1))
                : (
              !props.memberVipInfo?.hiddenNeedPoints &&
              props.memberVipInfo?.hiddenNeedDeposit
            )
                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_unref(t)('global_upgrade_requires_score_text', {
                grade: viewVipLevel(),
                score: _ctx.formatNum({
                  number: props.memberVipInfo?.upNeedPoints || 0
                })
              })), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(t)('global_upgrade_requires_text', {
                grade: viewVipLevel(),
                amount: _ctx.formatNum({
                  number: props.memberVipInfo?.upNeedDeposit || 0
                }),
                score: _ctx.formatNum({
                  number: props.memberVipInfo?.upNeedPoints || 0
                })
              })), 1)),
              _createElementVNode("img", {
                src: _ctx.getImage('vip_icon_question'),
                alt: "vip_icon_question",
                onClick: _withModifiers(showTips, ["stop"]),
                class: "icon"
              }, null, 8, _hoisted_21)
            ], 64))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ], 6))
}
}

})